import React,{useState} from 'react'
import "./Process.style.scss"
import Fade from 'react-reveal/Fade';

const Process = () => {
    const [radioState, setRadioState] = useState("システムの要件を確定した後、同要件を確実に作り終わってから確認、受入れを行っていただいた場合、確認、受入れ時にお客様から「もっとこうしてもらいたい」と言った話が往々にして出てきます。これは、“実際に動くものを見たら、より具体的に実際の業務と照らし合わせてシステムのことを考える”からです。ただし、このギャップが多いほど、「仕様変更」や「追加要件」といった紆余曲折を生みやすくなり、プロジェクトとしてはよろしくない方向に進んでしまうことがあります。弊社では最小単位で開発→確認を行っていただくアジャイル開発のアプローチを採用しており、要望を取り入れながら開発を進めていき、導入時点のギャップを最小限に抑えます。")
    const handleSelectChange = (e) => {
        setRadioState(e.target.value);
    }

    
    return (
        <>
        <div className="img_container">
                <h5 style={{marginBottom:"30px"}} className="our_text">Our Process</h5>
                <Fade delay={500} cascade>
                    <p className="our_explanation_text">{radioState}</p>
                </Fade>
            </div>
            <hr style={{width: "70%", marginTop: "3rem"}} />
            <div className="process-radio-container">
                <input type="radio" onChange={handleSelectChange} defaultChecked  value={"システムの要件を確定した後、同要件を確実に作り終わってから確認、受入れを行っていただいた場合、確認、受入れ時にお客様から「もっとこうしてもらいたい」と言った話が往々にして出てきます。これは、“実際に動くものを見たら、より具体的に実際の業務と照らし合わせてシステムのことを考える”からです。ただし、このギャップが多いほど、「仕様変更」や「追加要件」といった紆余曲折を生みやすくなり、プロジェクトとしてはよろしくない方向に進んでしまうことがあります。弊社では最小単位で開発→確認を行っていただくアジャイル開発のアプローチを採用しており、要望を取り入れながら開発を進めていき、導入時点のギャップを最小限に抑えます。"} id="process_radio_1" name="process_radio" />
                <input type="radio" onChange={handleSelectChange}  value={"システムを新たに構築する場合、効果的にシステム導入するために実際にシステム化する範囲（スコープ）と段階（ステップ）を決めます。全ての要件を一機にシステム化して導入したほうが良い場合もありますが、特に現行の業務を移行していく場合は、複数のスコープに分けてシステム構築→導入を段階毎に徐々に行ったほうが良い場合があります。システム化スコープ、およびステップの定義を導入の計画とともにまとめます。"} id="process_radio_2" name="process_radio" />
                <input type="radio" onChange={handleSelectChange}  value={"お客様の「こういうものが作りたい」について、弊社のゼロベースコンサルタントが整理いたします。考えがまだ整理できていない段階であったとしても、弊社のフレームワークに沿ってコンサルティングを行わせていただき、本質に何が必要なのか？をお客様と一緒にまとめます。アウトプットとして、サービスやシステムを改めて作ったほうが良い場合は、さらにそれらに必要な要件をまとめます。サービスやシステムを改めて作らなくてもよい場合は現状の業務等の整理をどのように行うべきかの課題解決を行わせていただきます。"} id="process_radio_3" name="process_radio" />
                <input type="radio" onChange={handleSelectChange}  value={"当然のことですが、サービスやシステムが完成したらゴールではありません。特に業務システムにおいては、①従業員がシステムを利用できる。②システム担当者がシステムを理解して日々の運用が可能となる。③従業員がシステムを使った次の一手をシステム担当者に相談できる。ということが必要になります。特に②や③については、導入したシステムを事業戦略に有効な形で活用するためには必要不可欠です。必要な場合はシステム担当者様のスキル向上も含めて、弊社メソッドを提供させていただきます。"} id="process_radio_4" name="process_radio" />
            </div>
            <div className="video_container">
                <div className="video_wrap">
                    <iframe className="video" src="https://www.youtube.com/embed/u-oDkzv_M6s" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                </div>
            </div>
                <Fade delay={500} cascade>
            <div className="video_text_container">
                <p className="video_text">SPIN　D&Dでは、動画編集などでも実績があり、上記のDAOKOさんの映像も最先端の映像技術で作成しています。<br />
                    また、VRとARをIT技術と組み合わせることにより、携帯で映像を操作する事などにも実績があります。
                </p>
            </div>
                </Fade>
        </>
    )
}

export default Process;