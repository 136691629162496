import React from 'react'
import './Introduction.style.scss'
import Fade from 'react-reveal/Fade';


const Introduction = () => {
    return (
        <>
            <div className="introduction_container">
                <div className="title_container">
                <h5 className="introduction_main_text"><span className="introduction_text_before"></span>WHAT WE DO<span className="introduction_text_after"></span></h5>
                </div>
                <Fade delay={500} cascade>
                <p>SPIN D&Dは【System Integration】【VR Development】【3DCG Design】の３つの分野を融合した精鋭軍団です。デジタルトランスフォーメーションを伴うシステム開発からVR、ARコンテンツの制作まで可能なスペシャリストたちがICT×CGで問題解決のお手伝いを行います。</p>
                </Fade>
                <hr className="content_border" />
            </div>
            <div className="introduction_text_container">
                    <div className="introduction_item">
                    <h5><span className="introduction_text_margin">SYSTEM</span>INTEGRATION</h5>
                    <hr className="cp_hr06" />
                    <Fade delay={500}  cascade>
                        <p style={{marginTop:"1.5rem"}} className="item_text_introduction">ITスペシャリストチームがこれまでに蓄積してきた開発ノウハウを新テクノロジーと融合させ、業務ヒアリングからシステム定着まで一気通貫したシステム開発を行います。SPIN D&Dとしては、AIを活用した建築関連のデジタルトランスフォーメーション案件、素材メーカー様向けスケジュール型デジタルサイネージシステムの構築案件等を幅広くご依頼いただいています。</p>
                    </Fade>
                    </div>
                <div className="introduction_item">
                        <h5><span className="introduction_text_margin">XR</span>DEVELOPMENT</h5>
                    <hr className="cp_hr06" />
                    <Fade delay={500}  cascade>
                        <p style={{marginTop:"1.5rem"}} className="item_text_introduction">SPIN D&Dには建築業界のXR分野に一石を投じてきたクリエイター＆デベロッパーが所属しています。Unreal Engine 4（UE4）を使った建築業界向けのXRサービスを多数開発しています。例えば、大手ゼネコン様向けのBuilding Information Modeling（BIM）に連携したVRサービスの構築、設計事務所様向けの展示用ARサービスの構築等を手掛けさせていただいております。</p>
                    </Fade>
                    </div>
                <div className="introduction_item">
                        <h5><span className="introduction_text_margin">3DCG</span>DESIGN</h5>
                    <hr className="cp_hr06" />
                    <Fade delay={500}  cascade>
                        <p style={{marginTop:"1.5rem"}} className="item_text_introduction">SPIN D&Dには、様々な領域のDesignerやCreatorがいます。3D Computer Graphics、Movie, Photo Creator（Droneでの撮影も可能）がお客様からのご要望に様々な視点からお応えします。某アーティスト様のMusic Videoの背景CG素材作りや、ドローン飛行しながらの全天球動画の撮影等、医療用CGカットの作成など多岐にわたるお客様からお声掛けいただいております。</p>
                    </Fade>
                    </div>
            </div>
            
            <div className="loop_img_container">
                {/* <Canvas>
			    <mesh>
				<UseModel />
			    </mesh>
		        </Canvas> */}
                <span className="img-1"><img src={`/it_computer_programming.jpg`} alt="" /></span>
                <span className="img-2"><img src={`https://spin-dd.com/wp-content/uploads/2020/01/thumbnail_02-750x499.png`}  alt=""/></span>
                <span className="img-3"><img src={`https://spin-dd.com/wp-content/uploads/2016/07/vrdev_02_thumbail-750x499.jpg`}  alt=""/></span>
                <span className="img-4"><img src={`https://spin-dd.com/wp-content/uploads/2016/07/ar01-750x499.jpg`}  alt=""/></span>
                <span className="img-5"><img src={`https://spin-dd.com/wp-content/uploads/2016/07/side.jpg`}  alt=""/></span>
                <span className="img-6"><img src={`https://spin-dd.com/wp-content/uploads/2016/07/ds01-750x499.jpg`}  alt=""/></span>
                <span className="img-7"><img src={`/it_computer_programming.jpg`}  alt=""/></span>
                <span className="img-8"><img src={`/it_computer_programming.jpg`}  alt=""/></span>
            </div>
            
        </>
    )
}

export default Introduction;